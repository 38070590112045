import React from 'react';
import { H4, Heading } from '../../components';
import { richText } from '../../util/richText';

import css from './ListingPage.module.css';
import { FormattedMessage } from 'react-intl';
import Icons from '../../components/Icons/Icons';

const MIN_LENGTH_FOR_LONG_WORDS = 20;

const SectionTextMaybe = props => {
  const { text, heading, showAsIngress = false, publicData } = props;
  const textClass = showAsIngress ? css.ingress : css.text;
  const content = richText(text, {
    linkify: true,
    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
    longWordClass: css.longWord,
    breakChars: '/',
  });

  return text ? (
    <section className={css.sectionText}>
      {/* {heading ? (
        <Heading as="h2" rootClassName={css.sectionHeading}>
          <FormattedMessage id="ListingPage.orderTitle" />
        </Heading>
      ) : null} */}
      <p className={css.location}>
        <Icons name="loction" /> {publicData?.location?.address}
      </p>
      <p className={textClass}>{content}</p>
    </section>
  ) : null;
};

export default SectionTextMaybe;
